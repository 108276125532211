<template>
  <div>
    <header>
    <SecondNavBar/>
    </header>
      <div class="row">
          <h5 class="purple-title col-md-4 offset-md-3">Privacy Statement</h5>
      </div>
      <div class="row">
          <p class="offset-md-3 col-md-7">
              Home for Life Design is committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Statement of Privacy applies to the Home for Life Design Web site and governs data collection and usage. By using the Home for Life Design website, you consent to the data practices described in this statement.
          </p>
      </div>
      <div class="row">
          <h5 class="col-md-4 offset-md-3"><strong>Collection of your Personal Information</strong></h5>
      </div>
      <div class="row">
          <p class="offset-md-3 col-md-7">
              Home for Life Design collects personally identifiable information, such as your e-mail address, name, home or work address or telephone number. Home for Life Design also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favorites.
          </p >
          <p class="offset-md-3 col-md-7">
              There is also information about your computer hardware and software that is automatically collected by Home for Life Design. This information can include: your IP address, browser type, domain names, access times and referring Web site addresses. This information is used by Home for Life Design for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Home for Life Design Web site.
          </p>
          <p class="offset-md-3 col-md-7">
              Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Home for Life Design public message boards, this information may be collected and used by others. Note: Home for Life Design does not read any of your private online communications.
          </p>
          <p class="offset-md-3 col-md-7">
              Home for Life Design encourages you to review the privacy statements of Web sites you choose to link to from Home for Life Design so that you can understand how those Web sites collect, use and share your information. Home for Life Design is not responsible for the privacy statements or other content on Web sites outside of the Home for Life Design and Home for Life Design family of Web sites.
          </p>
      </div>
      <div class="row">
          <h5 class="col-md-4 offset-md-3"><strong>Use of your Personal Information</strong></h5>
      </div>
      <div class="row">
          <p class="offset-md-3 col-md-7">
              Home for Life Design collects and uses your personal information to operate the Home for Life Design Web site and deliver the services you have requested. Home for Life Design also uses your personally identifiable information to inform you of other products or services available from Home for Life Design and its affiliates. Home for Life Design may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered.
          </p>
          <p class="offset-md-3 col-md-7">
              Home for Life Design does not sell, rent or lease its customer lists to third parties. Home for Life Design may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. In addition, Home for Life Design may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Home for Life Design, and they are required to maintain the confidentiality of your information.
          </p>
          <p class="offset-md-3 col-md-7">
              Home for Life Design does not use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent.
          </p>
          <p class="offset-md-3 col-md-7">
              Home for Life Design keeps track of the Web sites and pages our customers visit within Home for Life Design, in order to determine what Home for Life Design services are the most popular. This data is used to deliver customized content and advertising within Home for Life Design to customers whose behavior indicates that they are interested in a particular subject area.
          </p>
          <p class="offset-md-3 col-md-7">
              Home for Life Design Web sites will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Home for Life Design or the site; (b) protect and defend the rights or property of Home for Life Design; and, (c) act under exigent circumstances to protect the personal safety of users of Home for Life Design, or the public.
          </p>
      </div>
      <div class="row">
          <h5 class="col-md-4 offset-md-3"><strong>Use of Cookies</strong></h5>
      </div>
      <div class="row">
          <p class="offset-md-3 col-md-7">
              The Home for Life Design Web site use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a Web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
          </p>
          <p class="offset-md-3 col-md-7">
              One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Home for Life Design pages, or register with Home for Life Design site or services, a cookie helps Home for Life Design to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Home for Life Design Web site, the information you previously provided can be retrieved, so you can easily use the Home for Life Design features that you customized.
          </p>
          <p class="offset-md-3 col-md-7">
              You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Home for Life Design services or Web sites you visit.
          </p>
      </div>
      <div class="row">
          <h5 class="col-md-4 offset-md-3"><strong>Security of your Personal Information</strong></h5>
      </div>
      <div class="row">
          <p class="offset-md-3 col-md-7">Home for Life Design secures your personal information from unauthorized access, use or disclosure. Home for Life Design secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other Web sites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.</p>
      </div>
      <div class="row">
          <h5 class="col-md-4 offset-md-3"><strong>Changes to this Statement</strong></h5>
      </div>
      <div class="row">
          <p class="offset-md-3 col-md-7">Home for Life Design will occasionally update this Statement of Privacy to reflect company and customer feedback. Home for Life Design encourages you to periodically review this Statement to be informed of how Home for Life Design is protecting your information.</p>
      </div>
      <div class="row">
          <h5 class="col-md-4 offset-md-3"><strong>Contact Information</strong></h5>
      </div>
      <div class="row">
          <p class="offset-md-3 col-md-7">Home for Life Design welcomes your comments regarding this Statement of Privacy. If you believe that Home for Life Design has not adhered to this Statement, please contact Home for Life Design at info@homeforlifedesign.com. We will use commercially reasonable efforts to promptly determine and remedy the problem.</p>
      </div>
  </div>
</template>

<script>
import SecondNavBar from '../../components/LandingPage/SecondNavBar'
export default {
    components:{
        SecondNavBar
    }
}
</script>

<style>

</style>